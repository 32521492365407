<template>
  <div class="statistics">
    <el-row
      class="statistics__row"
      :gutter="20"
      type="flex"
      align="middle">
      <el-col
        :span="12"
        class="statistics__col">
        <h1 class="title title--blue title--statistics">{{ $t('statistics.title') }}</h1>
      </el-col>
      <el-col
        :span="12"
        class="statistics__col statistics__col--date-filter">
        <div>
          <p class="statistics__filter-label">{{ $t('statistics.filterLabel') }}</p>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            align="right"
            format="dd/MM/yyyy"
            value-format="yyyy-MM-dd"
            @change="getRemoteStatistics()"
            :start-placeholder="$t('statistics.startDate')"
            :end-placeholder="$t('statistics.finishDate')">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>

    <section class="user-section">
      <div class="statistics__section-title">
        <p class="">
          {{ $t('statistics.user') }}
          <span class="statistics__highlighted statistics__highlighted--margin-right">
            {{ currentUser.user_display_name }}
          </span>
          {{ $t('statistics.policiesTotal') }}
          <span class="statistics__highlighted">
            {{ userStatistics.new_policies_total }}
          </span>
        </p>
      </div>
      <StatisticsGraph
        v-loading="isLoadingUser"
        :statistics="userStatistics"
        :series="userSeries"
        :newBusinessChartRef="userNewBusinessChart"
        :portfolioChartRef="userPortfolioChart"
        :portfolioLabels="portfolioLabels">
      </StatisticsGraph>
    </section>

    <section class="group-section" v-if="groupStatistics">
      <div class="statistics__section-title">
        <p class="">
          {{ $t('statistics.group') }}
          <span class="statistics__highlighted statistics__highlighted--margin-right">
            {{ currentUser.user_group.title }}
          </span>
          {{ $t('statistics.policiesTotal') }}
          <span class="statistics__highlighted">
            {{ groupStatistics.new_policies_total }}
          </span>
        </p>
      </div>
      <StatisticsGraph
        v-loading="isLoadingGroup"
        :statistics="groupStatistics"
        :series="groupSeries"
        :newBusinessChartRef="groupNewBusinessChart"
        :portfolioChartRef="groupPortfolioChart"
        :portfolioLabels="portfolioLabels">
      </StatisticsGraph>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { usersApi } from '@/api';
import StatisticsGraph from '@/components/StatisticsGraph.vue';

export default {
  name: 'Statistics',
  components: {
    StatisticsGraph,
  },
  data() {
    return {
      dateRange: [
        this.firstDayCurrentYear(),
        this.today(),
      ],
      userStatistics: {},
      groupStatistics: false,
      userNewBusinessChart: 'userNewBusinessChart',
      userPortfolioChart: 'userPortfolioChart',
      groupNewBusinessChart: 'groupNewBusinessChart',
      groupPortfolioChart: 'groupPortfolioChart',
      isLoadingUser: false,
      isLoadingGroup: false,
      newBusinessSeriesMapping: [
        { label: 'fundsLabel', key: 'new_business_funds' },
        { label: 'gsLabel', key: 'new_business_gs' },
        { label: 'cteLabel', key: 'new_business_cte' },
      ],
      portfolioLabels: [
        this.$t('statistics.fundsLabel'),
        this.$t('statistics.gsLabel'),
        this.$t('statistics.cteLabel'),
      ],
      userSeries: {
        newBusiness: [],
        portfolio: [],
      },
      groupSeries: {
        newBusiness: [],
        portfolio: [],
      },
    };
  },
  created() {
    this.getRemoteStatistics();
  },
  beforeRouteUpdate(to, from, next) {
    this.getRemoteStatistics();
    next();
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
  },
  methods: {
    buildChartData(chartData) {
      const userData = chartData.user.data || null;
      this.userSeries.newBusiness = this.newBusinessSeriesMapping.map((item) => ({
        name: this.$t(`statistics.${item.label}`),
        data: userData ? userData[item.key] : [],
      }));
      this.userSeries.portfolio = userData.portfolio;

      if (chartData.group) {
        const groupData = chartData.group.data || null;
        this.groupSeries.newBusiness = this.newBusinessSeriesMapping.map((item) => ({
          name: this.$t(`statistics.${item.label}`),
          data: groupData ? groupData[item.key] : [],
        }));
        this.groupSeries.portfolio = groupData.portfolio;
      }
    },
    async getRemoteStatistics() {
      try {
        this.isLoadingUser = true;
        this.isLoadingGroup = true;
        const params = {
          operator_code: this.currentUser.user_code,
          group_code: this.currentUser.user_group.code,
          user_role: this.currentUser.user_reserved_area_role,
          date_range: this.dateRange,
        };
        const statisticsResponse = await usersApi.getStatistics(params);
        this.userStatistics = statisticsResponse.data.user;
        this.groupStatistics = statisticsResponse.data.group;
        this.buildChartData(statisticsResponse.data);
        this.isLoadingUser = false;
        this.isLoadingGroup = false;
      } catch (error) {
        this.isLoadingUser = false;
        this.isLoadingGroup = false;
        if (error && error.response) {
          this.$notify({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: error.response.data.message,
          });
        }
        throw error;
      }
    },
    firstDayCurrentYear() {
      return `${new Date().getFullYear()}-01-01`;
    },
    today() {
      return `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
    },
  },
};
</script>
