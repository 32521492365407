<template>
  <div
    class="statistics__box">
    <el-row
      class="statistics__row"
      :gutter="20"
      type="flex">
      <el-col
        :span="12"
        class="statistics__col">
        <el-row
          type="flex"
          align="middle"
          justify="space-between">
          <p class="statistics__box-title">{{ $t('statistics.newBusiness') }}</p>
          <p class="statistics__box-volume">
            {{ $t('statistics.policiesVolume') }}
            <span class="statistics__highlighted">
              € {{ statistics.new_business_total }}
            </span>
          </p>
        </el-row>
        <div class="statistics__chart">
          <apexchart
            ref="newBusinessChartRef"
            width="100%"
            type="bar"
            :options="options"
            :series="series.newBusiness">
          </apexchart>
        </div>
        <el-row
          type="flex"
          align="middle"
          justify="space-between">
          <p class="statistics__box-disclaimer">
            <span v-if="currentUser.user_group.barclays_disclaimer != true">
              {{ $t('statistics.boxDisclaimer') }}
            </span>
          </p>
          <p class="statistics__box-subscriptions">{{ $t('statistics.subscriptions') }}</p>
        </el-row>
      </el-col>
      <el-col
        :span="12"
        class="statistics__col">
        <el-row
          type="flex"
          align="middle"
          justify="space-between">
          <p class="statistics__box-title">{{ $t('statistics.portfolio') }}</p>
          <p class="statistics__box-volume">
            {{ $t('statistics.assetsUnderManagement') }}
            <span class="statistics__highlighted">
              € {{ statistics.assets_under_management }}
            </span>
          </p>
        </el-row>
        <div class="statistics__chart">
          <apexchart
            ref="portfolioChartRef"
            width="100%"
            type="pie"
            :options="pieOptions"
            :series="series.portfolio">
          </apexchart>
        </div>
        <el-row
          type="flex"
          align="middle"
          justify="space-between">
          <p class="statistics__box-disclaimer">
            <span v-if="currentUser.user_group.barclays_disclaimer != true">
              {{ $t('statistics.boxDisclaimer') }}
            </span>
          </p>
          <p class="statistics__box-subscriptions">{{ $t('statistics.subscriptions') }}</p>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { mapGetters } from 'vuex';

export default {
  name: 'StatisticsGraph',
  props: [
    'statistics',
    'series',
    'newBusinessChartRef',
    'portfolioChartRef',
    'portfolioLabels',
  ],
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      pieOptions: {
        chart: {
          id: 'portfolio-chart',
          type: 'pie',
          fontFamily: 'Open Sans, sans-serif',
          toolbar: {
            show: true,
          },
        },
        labels: [
          ...this.portfolioLabels,
        ],
        legend: {
          position: 'right',
          offsetY: 20,
        },
        colors: [
          '#01b4a9',
          '#002363',
          '#db0169',
        ],
        plotOptions: {
          pie: {
            customScale: 0.8,
          },
        },
      },
      options: {
        chart: {
          id: 'statistics-chart',
          stacked: true,
          fontFamily: 'Open Sans, sans-serif',
          zoom: {
            enabled: true,
            type: 'x',
            autoScaleYaxis: false,
          },
          toolbar: {
            autoSelected: 'pan',
          },
        },
        legend: {
          position: 'right',
          showForZeroSeries: false,
          offsetY: 20,
        },
        dataLabels: {
          enabled: false,
          formatter(value) {
            return Math.abs(value) > 999
              ? `€ ${Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1))} k`
              : `€ ${Math.sign(value) * Math.abs(value)}`;
          },
        },
        fill: {
          opacity: 1,
        },
        colors: [
          '#01b4a9',
          '#002363',
          '#db0169',
        ],
        xaxis: {
          tickPlacement: 'on',
          tickAmount: 6,
        },
        yaxis: [{
          labels: {
            formatter(value) {
              return Math.abs(value) > 999
                ? `€ ${Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1))} k`
                : `€ ${Math.sign(value) * Math.abs(value)}`;
            },
          },
        }],
      },
    };
  },
  computed: {
    ...mapGetters('users', ['currentUser']),
  },
};
</script>
